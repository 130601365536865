import { gql } from "@apollo/client";
import { getApiGraphQL } from "./api-graphql";
import {
  AbsenceConfig,
  AddNewEmployeePermission,
  EmployeeVerificationFields,
  OrganizationHierarchy,
  AbsenceIncident,
  IncidentQuestionModel,
  IncidentSaveResult,
  IncidentsCancelResult,
  IncidentSubmitResult,
  LatestInProgressAbsenceResult,
  IncidentClosingScript,
  ApiCallResult
} from "features/report-absence/incident";
import { IAbsenceReportingService } from "features/report-absence/absence-reporting-types";
import * as fragments from "./fragments";
import {
  AbsenceConfigQuery,
  AddNewEmployeePermissionQuery,
  EmployeeVerificationFieldsQuery,
  OrganizationHierarchyQuery,
  SaveEmployeeModelInput,
  CancelAbsenceIncidentsMutation,
  ConfigQuestionsQuery,
  LatestInProgressAbsenceForEmployeeQuery,
  LoadIncidentQuery,
  SaveAbsenceMutation,
  SaveAbsenceMutationVariables,
  SubmitAbsenceMutation,
  AbsenceIncidentClosingScriptsQuery,
  AddNewEmployeeMutation,
  CancelAbsenceRequestModelInput
} from "graphql-types.gen";
import {
  parseAbsenceIncident,
  parseClosingScripts,
  parseQuestionReturnModel,
  parseReasonOfAbsence,
  parseEmployeeVerificationFields,
  parseOrganizationHierarchy,
  toAbsenceIncidentInputModelInput,
  parseValidationErrors,
  parseWorkScheduleInfo,
  parseLoadedAbsenceIncident,
  parseLocaleModel,
  parseAR3IVRConfiguration
} from "features/report-absence/api-parse-helpers";
import { makeTime, parseTime } from "shared-types/time";
import { locale } from "faker/locale/ge";

export class ApiAbsenceReportingService implements IAbsenceReportingService {
  loadIncident = async (incidentId: number): Promise<AbsenceIncident | null> =>
    new Promise<AbsenceIncident>((resolve, reject) => {
      getApiGraphQL()
        .query<LoadIncidentQuery>({
          query: gql`
            query LoadIncident($incidentId: Long!) {
              absenceIncident(id: $incidentId) {
                ...absenceIncidentFields
                CanBeALinkedIncident {
                  canBeLinked
                  latestDate
                  totalNoOfDaysReportedExcludingCurrent
                }
                claimStatus
              }
            }
            ${fragments.absenceIncidentFields}
          `,
          variables: {
            incidentId: incidentId
          }
        })
        .then(result => {
          if (result.data && result.data.absenceIncident) {
            resolve(parseLoadedAbsenceIncident(result.data.absenceIncident));
          } else reject(result);
        })
        .catch(error => {
          reject(error);
        });
    });

  saveIncident = async (
    clientCode: string,
    incident: AbsenceIncident,
    askIfCanBeLinked: boolean,
    locale: string
  ): Promise<IncidentSaveResult> =>
    new Promise<IncidentSaveResult>((resolve, reject) => {
      if (!incident.absenceIncidentId) {
        // need to do temporary hack with removing property for new absence
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { absenceIncidentId, ...input } = incident;
        incident = input;
      }

      getApiGraphQL()
        .mutate<SaveAbsenceMutation, SaveAbsenceMutationVariables>({
          mutation: gql`
            mutation SaveAbsence(
              $clientCode: String!
              $input: AbsenceIncidentInputModelInput
              $askIfCanBeLinked: Boolean!
            ) {
              saveAbsenceIncident(clientCode: $clientCode, input: $input) {
                returnValue {
                  absenceIncidentModel {
                    ...absenceIncidentFields
                    CanBeALinkedIncident @include(if: $askIfCanBeLinked) {
                      canBeLinked
                      latestDate
                      totalNoOfDaysReportedExcludingCurrent
                    }
                  }
                  absenceQuestionReturnModel {
                    ...incidentQuestionFields
                  }
                }
                success
                validationErrors {
                  errorCode
                  errorDescription
                  errorDescriptionLocales {
                    description
                    locale
                  }
                }
              }
            }
            ${fragments.absenceIncidentFields}
            ${fragments.incidentQuestionFields}
          `,
          variables: {
            clientCode,
            input: toAbsenceIncidentInputModelInput(incident, locale),
            askIfCanBeLinked
          }
        })
        .then(result => {
          if (!result.errors && result.data?.saveAbsenceIncident) {
            const data = result.data.saveAbsenceIncident;

            if (data.success) {
              if (!data.returnValue?.absenceIncidentModel) {
                throw new Error(
                  "Failed parsing incident model after incident was saved"
                );
              }

              if (!data.returnValue?.absenceQuestionReturnModel) {
                console.error(
                  "Expected absenceQuestionReturnModel not to be null"
                );
              }

              const saveResult: IncidentSaveResult = {
                returnValue: {
                  absenceIncidentModel: parseAbsenceIncident(
                    data.returnValue?.absenceIncidentModel
                  ),
                  absenceQuestionReturnModel: parseQuestionReturnModel(
                    data.returnValue?.absenceQuestionReturnModel ?? undefined
                  )
                },
                success: true,
                validationErrors: parseValidationErrors(
                  locale,
                  data.validationErrors
                )
              };
              resolve(saveResult);
            } else {
              resolve({
                success: false,
                validationErrors: parseValidationErrors(
                  locale,
                  data.validationErrors
                )
              });
            }
          } else {
            console.error(result.errors || "save absence failure");
            reject(result);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  submitIncident = async (
    incident: AbsenceIncident,
    locale: string
  ): Promise<IncidentSubmitResult> =>
    new Promise<IncidentSubmitResult>((resolve, reject) => {
      getApiGraphQL()
        .mutate<SubmitAbsenceMutation>({
          mutation: gql`
            mutation SubmitAbsence(
              $incidentId: Long!
              $input: AbsenceIncidentInputModelInput
            ) {
              submitAbsenceIncident(incidentId: $incidentId, input: $input) {
                ...incidentSubmitReturn
              }
            }
            ${fragments.incidentSubmitReturn}
          `,
          variables: {
            incidentId: incident.absenceIncidentId,
            input: toAbsenceIncidentInputModelInput(incident, locale)
          }
        })
        .then(async result => {
          if (result.data && result.data.submitAbsenceIncident) {
            const data = result.data.submitAbsenceIncident;

            if (result.data.submitAbsenceIncident?.success) {
              if (!data.returnValue?.absenceIncidentModel) {
                throw new Error(
                  "Failed parsing incident model after incident was saved"
                );
              }

              const submitResult: IncidentSubmitResult = {
                returnValue: {
                  absenceIncidentModel: parseAbsenceIncident(
                    data.returnValue?.absenceIncidentModel
                  ),
                  closingScripts: []
                },
                success: data.success,
                validationErrors: parseValidationErrors(
                  locale,
                  data.validationErrors
                )
              };

              if (data.success && submitResult.returnValue) {
                const closingScripts = await this.loadClosingScript(
                  incident.absenceIncidentId ?? 0
                );
                submitResult.returnValue.closingScripts = closingScripts;
              }

              resolve(submitResult);
            } else {
              const submitResult: IncidentSubmitResult = {
                success: false,
                validationErrors: parseValidationErrors(
                  locale,
                  data.validationErrors
                )
              };

              resolve(submitResult);
            }
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  loadConfig = async (clientCode: string, state: string): Promise<AbsenceConfig> =>
    new Promise<AbsenceConfig>((resolve, reject) => {
      getApiGraphQL()
        .query<AbsenceConfigQuery>({
          query: gql`
            query AbsenceConfig($clientCode: String!
              $state: String!
              ) {
              absenceReportingConfig(clientCode: $clientCode
                state: $state
                ) {
                reasonOfAbsence {
                  answers {
                    answerId
                    locales {
                      locale
                      description
                    }
                    iconResource {
                      sourceUrl
                    }
                    maxDaysAllowed
                    reportableDaysInFuture
                    reportableDaysInPast
                    subAnswers {
                      answerId
                      locales {
                        locale
                        description
                      }
                      reportableDaysInFuture
                      reportableDaysInPast
                    }
                  }
                }
                displayRTWDate_Continous
                displayRTWDate_Intermittent
                isPartialAbsences
                isRTWDateMandatory_Continous
                isRTWDateMandatory_Intermittent
                absenceDurationMinutes
                minimumThresholdForContinousAbsence
                workScheduleInfo {
                  endNoOfDays
                  startNoOfDays
                  warnWhenOverridingSchedule
                  warningMessages {
                    locale
                    description
                  }
                }
                workShifts {
                  startTimeHours
                  startTimeMinutes
                  endTimeHours
                  endTimeMinutes
                  isDefault
                  order
                }
                closingScripts {
                  text {
                    description
                    locale
                  }
                }
                aR3IvrConfiguration {
                  maxShiftLengthThresholdInMinutes
                }
              }
            }
          `,
          variables: {
            clientCode: clientCode, 
            state: state,
          }
        })
        .then(result => {
          if (result.data && result.data.absenceReportingConfig) {
            const config: AbsenceConfig = {
              absenceDurationMinutes:
                result.data.absenceReportingConfig?.absenceDurationMinutes,
              isPartialAbsences:
                result.data.absenceReportingConfig.isPartialAbsences,
              reasonOfAbsence: parseReasonOfAbsence(
                result.data.absenceReportingConfig.reasonOfAbsence
              ),
              displayRTWDateContinuous:
                result.data.absenceReportingConfig.displayRTWDate_Continous,
              displayRTWDateIntermittent:
                result.data.absenceReportingConfig.displayRTWDate_Intermittent,
              isRTWDateMandatoryContinuous:
                result.data.absenceReportingConfig.isRTWDateMandatory_Continous,
              isRTWDateMandatoryIntermittent:
                result.data.absenceReportingConfig
                  .isRTWDateMandatory_Intermittent,
              minimumThresholdForContinousAbsence:
                result.data.absenceReportingConfig
                  .minimumThresholdForContinousAbsence,
              workScheduleInfo: parseWorkScheduleInfo(
                result.data.absenceReportingConfig.workScheduleInfo
              ),
              workShifts: result.data.absenceReportingConfig.workShifts.map(
                ws => {
                  const startTime = parseTime(
                    `${ws.startTimeHours}:${ws.startTimeMinutes}`,
                    makeTime(9, 0, "am")
                  );
                  const endTime = parseTime(
                    `${ws.endTimeHours}:${ws.endTimeMinutes}`,
                    makeTime(5, 0, "pm")
                  );
                  return {
                    ...ws,
                    startTime,
                    endTime
                  };
                }
              ),
              closingScripts: result.data.absenceReportingConfig.closingScripts
                ? result.data.absenceReportingConfig.closingScripts
                    .filter(cs => cs?.text)
                    .map(cs => (cs?.text ? parseLocaleModel(cs?.text) : []))
                : undefined,               
              aR3IvrConfiguration: parseAR3IVRConfiguration(result.data.absenceReportingConfig.aR3IvrConfiguration)
            };
            
            resolve(config);
          } else reject(result);
        })
        .catch(error => {
          reject(error);
        });
    });

  public loadClosingScript = async (
    incidentId: number
  ): Promise<IncidentClosingScript[]> =>
    new Promise<IncidentClosingScript[]>((resolve, reject) => {
      getApiGraphQL()
        .query<AbsenceIncidentClosingScriptsQuery>({
          query: gql`
            query absenceIncidentClosingScripts($incidentId: Long!) {
              absenceIncidentClosingScripts(incidentId: $incidentId) {
                returnValue {
                  closingScriptId
                  text {
                    description
                    locale
                  }
                }
              }
            }
          `,
          variables: {
            incidentId
          }
        })
        .then(result => {
          if (
            result.data &&
            result.data.absenceIncidentClosingScripts &&
            result.data.absenceIncidentClosingScripts.returnValue
          ) {
            if (
              result.data.absenceIncidentClosingScripts.returnValue.length === 0
            ) {
              resolve([]);
            } else {
              const closingScript: IncidentClosingScript[] = parseClosingScripts(
                result.data.absenceIncidentClosingScripts.returnValue
              );
              resolve(closingScript);
            }
          } else reject(result);
        })
        .catch(error => {
          reject(error);
        });
    });

  loadAddingNewEmployeePermission = async (
    clientCode: string
  ): Promise<AddNewEmployeePermission> =>
    new Promise<AddNewEmployeePermission>((resolve, reject) => {
      getApiGraphQL()
        .query<AddNewEmployeePermissionQuery>({
          query: gql`
            query AddNewEmployeePermission($clientCode: String!) {
              absenceReportingConfig(clientCode: $clientCode) {
                showAddNewEmployeeLink
              }
            }
          `,
          variables: {
            clientCode
          }
        })
        .then(result => {
          if (result.data && result.data.absenceReportingConfig) {
            const permission = {
              showAddNewEmployeeLink:
                result.data.absenceReportingConfig.showAddNewEmployeeLink ||
                false
            };

            resolve(permission);
          } else {
            reject(result);
          }
        })
        .catch(err => {
          reject(err);
        });
    });

  loadEmployeeVerificationFields = async (
    clientCode: string
  ): Promise<EmployeeVerificationFields[]> =>
    new Promise<EmployeeVerificationFields[]>((resolve, reject) => {
      getApiGraphQL()
        .query<EmployeeVerificationFieldsQuery>({
          query: gql`
            query EmployeeVerificationFields($clientCode: String!) {
              connect_employeeVerificationFields(
                clientCode: $clientCode
                forAddEmployee: true
              ) {
                pageId
                pageHeading {
                  description
                  locale
                }
                pageHeadingDescription {
                  description
                  locale
                }
                sections {
                  sectionId
                  sectionHeading {
                    description
                    locale
                  }
                  sectionHeadingDescription {
                    description
                    locale
                  }
                  questions {
                    alerts {
                      alertId
                      alertText {
                        description
                        locale
                      }
                    }
                    config {
                      allowDecimals
                      changeMonth
                      changeYear
                      linkedField
                      maxDate
                      maxLength
                      uRL
                    }
                    defaultValue
                    isReadOnly
                    isVisible
                    label {
                      description
                      locale
                    }
                    longDescription {
                      description
                      locale
                    }
                    name
                    options {
                      text {
                        description
                        locale
                      }
                      value
                      metadata {
                        key
                        value
                      }
                    }
                    order
                    placeholder {
                      description
                      locale
                    }
                    questionSubType
                    questionType
                    reloadIncidentData
                    resolvedValue
                    source
                    validationConstraint {
                      compare {
                        fieldToCompare
                        operator
                        valueToCompare
                      }
                      digits
                      max
                      min
                      number
                      required
                    }
                    visibilityConditions {
                      id
                      operator
                      values
                    }
                  }
                }
              }
            }
          `,
          variables: {
            clientCode
          }
        })
        .then(result => {
          if (result.data && result.data.connect_employeeVerificationFields) {
            resolve(
              parseEmployeeVerificationFields(
                result.data.connect_employeeVerificationFields
              )
            );
          } else {
            resolve([]);
          }
        })
        .catch(error => reject(error));
    });

  loadOrganizationHierarchy = async (
    clientCode: string
  ): Promise<OrganizationHierarchy[]> =>
    new Promise<OrganizationHierarchy[]>((resolve, reject) => {
      getApiGraphQL()
        .query<OrganizationHierarchyQuery>({
          query: gql`
            query OrganizationHierarchy($clientCode: String!) {
              organizationHierarchy(clientCode: $clientCode) {
                organizationId
                organizationLevel
                organizationName
                childOrganizations {
                  organizationId
                  organizationLevel
                  organizationName
                }
              }
            }
          `,
          variables: {
            clientCode
          }
        })
        .then(result => {
          if (result.data && result.data.organizationHierarchy) {
            resolve(
              parseOrganizationHierarchy(result?.data?.organizationHierarchy)
            );
          } else {
            resolve([]);
          }
        })
        .catch(error => reject(error));
    });

  addNewEmployee = async (
    clientCode: string,
    input: SaveEmployeeModelInput
  ): Promise<ApiCallResult> =>
    new Promise< ApiCallResult>((resolve, reject) => {
      getApiGraphQL()
        .mutate<AddNewEmployeeMutation>({
          mutation: gql`
            mutation AddNewEmployee(
              $clientCode: String
              $input: SaveEmployeeModelInput
            ) {
              addEmployee(clientCode: $clientCode, input: $input) {
                success
                validationErrors {
                  errorCode
                  errorDescription
                  errorFields
                  errorDescriptionLocales {
                    description
                    locale
                  }
                }
              }
            }
          `,
          variables: {
            clientCode,
            input
          }
        })
        .then(result => {
          const data = result.data?.addEmployee;
          const cancelResults: ApiCallResult = {
            success: data?.success ?? false,
            validationErrors: parseValidationErrors(
              locale,
              data?.validationErrors
            )
          };
          resolve(cancelResults);
        })
        .catch(error => {
          reject(error);
        });
    });

  loadConfigQuestions = async (
    incidentId: number
  ): Promise<IncidentQuestionModel> =>
    new Promise<IncidentQuestionModel>((resolve, reject) => {
      getApiGraphQL()
        .query<ConfigQuestionsQuery>({
          query: gql`
            query ConfigQuestions($incidentId: Long!) {
              absenceQuestions(incidentId: $incidentId) {
                ...incidentQuestionFields
              }
            }
            ${fragments.incidentQuestionFields}
          `,
          variables: {
            incidentId: incidentId
          }
        })
        .then(result => {
          if (result.data && result.data.absenceQuestions) {
            const parsedModel = parseQuestionReturnModel(
              result.data.absenceQuestions
            );

            resolve(parsedModel);
          } else reject(result);
        })
        .catch(error => {
          reject(error);
        });
    });

  cancelIncidents = async (
    cancelRequest: CancelAbsenceRequestModelInput,
    incidentIds: number[],
    locale: string
  ): Promise<IncidentsCancelResult> =>
    new Promise<IncidentsCancelResult>((resolve, reject) => {
      getApiGraphQL()
        .mutate<CancelAbsenceIncidentsMutation>({
          mutation: gql`
            mutation CancelAbsenceIncidents(
              $cancelRequest: CancelAbsenceRequestModelInput
              $incidentIds: [Long!]!
            ) {
              cancelAbsenceIncidents(
                cancelRequest: $cancelRequest
                incidentIds: $incidentIds
              ) {
                success
                validationErrors {
                  errorCode
                  errorDescription
                  errorFields
                  errorDescriptionLocales {
                    description
                    locale
                  }
                }
              }
            }
          `,
          variables: {
            cancelRequest,
            incidentIds
          }
        })
        .then(result => {
          const data = result.data?.cancelAbsenceIncidents;
          const cancelResult: IncidentsCancelResult = {
            success: data?.success ?? false,
            validationErrors: parseValidationErrors(
              locale,
              data?.validationErrors
            )
          };
          resolve(cancelResult);
        })
        .catch(error => {
          reject(error);
        });
    });

  getLatestInProgressAbsence = async (
    employeeNumber?: string,
    clientCode?: string
  ): Promise<LatestInProgressAbsenceResult | null> =>
    new Promise<LatestInProgressAbsenceResult | null>((resolve, reject) => {
      getApiGraphQL()
        .query<LatestInProgressAbsenceForEmployeeQuery>(
          !!employeeNumber
            ? {
                query: gql`
                  query LatestInProgressAbsenceForEmployee(
                    $employeeNumber: String!
                    $clientCode: String!
                  ) {
                    inProgressAbsences(
                      first: 1
                      order_by: { requestDate: DESC }
                      employeeNumber: $employeeNumber
                      clientCode: $clientCode
                    ) {
                      nodes {
                        absenceIncidentId
                      }
                    }
                  }
                `,
                variables: { employeeNumber, clientCode }
              }
            : {
                query: gql`
                  query LatestInProgressAbsence {
                    inProgressAbsences(
                      first: 1
                      order_by: { requestDate: DESC }
                    ) {
                      nodes {
                        absenceIncidentId
                      }
                    }
                  }
                `
              }
        )
        .then(result => {
          resolve(
            result.data.inProgressAbsences?.nodes &&
              result.data.inProgressAbsences?.nodes[0]
              ? {
                  absenceIncidentId:
                    result.data.inProgressAbsences?.nodes[0]?.absenceIncidentId
                }
              : null
          );
        })
        .catch(error => {
          reject(error);
        });
    });
}
