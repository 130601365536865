import { UserManager, WebStorageStateStore, Log, User } from "oidc-client";
import {
  IDENTITY_CONFIG,
  METADATA_OIDC,
  SIGNIN_PARAMS,
  URL_CONFIG
} from "./auth-config";
import { ISessionService } from "features/session/session-model";
import { getSessionService } from "features/session/session-service";
import { AppContext } from "context/app-context";
import { getWebConfigurationService } from "features/config/web-configuration-service";

export const signinCallbackPath = "signin-oidc";

export type AuthResult = {
  accessToken: string;
  idToken: string;
  hasEmployees: boolean;
  privileges: string[];
  isCICUser: boolean;
  accessibleClientCount: number;
};

export interface AuthContextService {
  signinRedirectCallback?: () => Promise<User>;
  logout?: () => void;
  signoutRedirectCallback?: () => void;
  signinRedirect?: () => void;
  signinSilentCallback?: () => Promise<User | undefined>;
  createSigninRequest?: () => void;
  getIdToken?: () => string;
  setSessionInfo?: (authResult: AuthResult) => void;
}

export default class AuthService implements AuthContextService {
  UserManager: UserManager;
  user?: User;
  sessionService: ISessionService;

  constructor() {
    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      monitorSession: false,
      metadata: {
        ...METADATA_OIDC
      }
    });

    // Logger
    Log.logger = console;
    Log.level = Log.DEBUG;

    this.UserManager.events.addUserLoaded(async user => {
      this.user = user;
      this.setSessionInfo({
        accessToken: user.access_token,
        idToken: user.id_token,
        hasEmployees: user.profile.can_manage_employees === "True",
        privileges: Array.isArray(user.profile.pv) ? user.profile.pv : [],
        isCICUser: user.profile.ee_no === "",
        accessibleClientCount: user.profile.accessible_client_count
      });

      await getWebConfigurationService()
        .canAccessApplication(user.profile.client_code)
        .then(result =>
          sessionStorage.setItem("hasAccess", result ? "true" : "false")
        );

      if (window.location.href.indexOf(signinCallbackPath) !== -1) {
        this.navigateToScreen();
      }
    });

    this.UserManager.events.addUserSignedOut(async () => {
      await this.signinRedirect();
    });

    this.UserManager.events.addSilentRenewError(async e => {
      console.log("silent renew error", e.message);
      if (e.message === "login_required") {
        await this.signinRedirect();
      }
    });
    this.sessionService = getSessionService();
  }

  async signinRedirectCallback(): Promise<User> {
    return this.UserManager.signinRedirectCallback();
  }

  signinRedirect = async () => {
    localStorage.setItem("redirectUri", window.location.pathname);

    const params = new URLSearchParams(window.location.search);
    if (params.has("language")) {
      localStorage.setItem(
        SIGNIN_PARAMS.language,
        params.get("language") ?? ""
      );
    }
    if (params.has("clientCode")) {
      localStorage.setItem(
        SIGNIN_PARAMS.clientCode,
        params.get("clientCode") ?? ""
      );
    }
    await this.UserManager.signinRedirect({});
  };

  navigateToScreen = () => {
    let redirectUri = !!localStorage.getItem("redirectUri")
      ? localStorage.getItem("redirectUri")
      : "/dashboard";

    if (this.sessionService.isCICUser()) redirectUri = "/report-absence";
    if (!this.sessionService.hasAccess()) redirectUri = "/access-denied";
    window.location.replace(redirectUri ?? "");
  };

  setSessionInfo(authResult: AuthResult) {
    this.sessionService.setSession(authResult.accessToken);
    sessionStorage.setItem("id_token", authResult.idToken);

    sessionStorage.setItem(
      "hasEmployees",
      authResult.hasEmployees ? "true" : "false"
    );

    sessionStorage.setItem(
      "isCICUser",
      authResult.isCICUser ? "true" : "false"
    );

    sessionStorage.setItem("privileges", JSON.stringify(authResult.privileges));

    sessionStorage.setItem(
      "accessibleClientCount",
      authResult.accessibleClientCount.toString()
    );

    const client_code = localStorage.getItem(SIGNIN_PARAMS.clientCode);
    if (client_code) {
      sessionStorage.setItem("client_code", client_code);
      localStorage.removeItem(SIGNIN_PARAMS.clientCode);
    }
  }

  getIdToken(): string {
    return sessionStorage.getItem("id_token") ?? "";
  }

  async signinSilentCallback(): Promise<User | undefined> {
    return this.UserManager.signinSilentCallback();
  }

  createSigninRequest = async () => {
    return this.UserManager.createSigninRequest();
  };

  logout = async () => {
    if (AppContext.isSandbox()) {
      return;
    }
  
    // Get the id_token from sessionStorage (assuming it exists)
    const idToken = sessionStorage.getItem("id_token");
    const post_logout_uri = encodeURI(IDENTITY_CONFIG.post_logout_redirect_uri);
    // Construct the logout URL with the 'culture' query parameter
    const culture = sessionStorage.getItem("lang"); 
    const logoutUrl = `${METADATA_OIDC.end_session_endpoint}?id_token_hint=${idToken}&post_logout_redirect_uri=${post_logout_uri}&culture=${culture}`;
  
    // Perform the logout by redirecting the user to the logout URL
    window.location.href = logoutUrl;
  
    // Clear localStorage and reset the session
    localStorage.clear();
    this.sessionService.setSession(null);
  
    // Clear any stale state
    await this.UserManager.clearStaleState();
  };

  signoutRedirectCallback = async () => {
    if (AppContext.isSandbox()) {
      window.location.replace("");
      return;
    }

    await this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      sessionStorage.clear();
      this.sessionService.setSession(null);
      window.location.replace(URL_CONFIG.react_app_redirect_url);
    });
    await this.UserManager.clearStaleState();
  };
}
