import {
  AbsenceAlert,
  AbsenceQuestionsPage,
  EntityLocale,
  NewEployeeQuestionsSection
} from "./absence-questions";
import { LocaleTextEntry, LocaleTexts } from "shared-types/api";
import { parseISO } from "date-fns";
import { Time } from "shared-types/time";
import { AbsenceDateModel } from "graphql-types.gen";
import { matchLocales } from "features/translations/locales";

export type AbsenceShift = {
  isPartialAbsence: boolean;
  shiftEndTime: Date;
  shiftStartTime: Date;
  scheduledShiftEndTime?: Date;
  scheduledShiftStartTime?: Date;
};

export type QuestionResponse = {
  key: string;
  value: string;
};

export type AbsenceType = "continuous" | "intermittent";

export type AbsenceIncidentLinkability = (
  | {
      canBeLinked?: false;
    }
  | {
      canBeLinked: true;
      latestDate: Date;
      isLinked?: boolean;
      totalNoOfDaysReportedExcludingCurrent?: number;
    }
) & {
  cache?: unknown;
};

export type AbsenceIncident = {
  primaryReason: string;
  secondaryReason?: string;
  absenceDates: AbsenceShift[];
  absenceIncidentId?: number;
  bestPhoneNumber?: string;
  firstName?: string;
  lastName?: string;
  requestDate?: Date;
  returnToWorkDate?: Date;
  questionResponses?: QuestionResponse[];
  absenceType?: AbsenceType;
  linkability: AbsenceIncidentLinkability;
  employeeNumber?: string;
  clientCode?: string;
  claimStatus?: string;
  maxDaysAllowed?: number;
  maxReportableDays?: number;
};

export type SubAnswer = {
  answerId: string;
  locales: LocaleTextEntry[];
  reportableDaysInFuture: number | null;
  reportableDaysInPast: number | null;
};

export type AbsenceReasonAnswerInfo = {
  answerId: string;
  maxDaysAllowed: number | null;
  reportableDaysInFuture: number | null;
  reportableDaysInPast: number | null;
  locales: LocaleTextEntry[];
  subAnswers: SubAnswer[];
  imageUrl?: string;
};

export type AbsenceReason = {
  answers: AbsenceReasonAnswerInfo[];
};

export type AbsenceConfig = {
  reasonOfAbsence: AbsenceReason;
  isPartialAbsences: boolean;
  displayRTWDateContinuous: boolean;
  displayRTWDateIntermittent: boolean;
  isRTWDateMandatoryContinuous: boolean;
  isRTWDateMandatoryIntermittent: boolean;
  absenceDurationMinutes: number;
  minimumThresholdForContinousAbsence?: number;
  workScheduleInfo: WorkScheduleInfo;
  linkedIncidentsLookbackThresholdDays?: number;
  workShifts: WorkShift[];
  closingScripts?: LocaleTexts[];
  aR3IvrConfiguration?: AR3IvrConfiguration;
};

export type AddNewEmployeePermission = {
  showAddNewEmployeeLink: boolean;
};

export type EmployeeVerificationFields = {
  pageId?: string | null;
  pageHeading?: EntityLocale[] | null;
  pageHeadingDescription?: EntityLocale[] | null;
  sections?: NewEployeeQuestionsSection[];
};

export type OrganizationModel = {
  organizationId: number;
  organizationLevel?: number | null;
  organizationName?: string | null;
};

export type OrganizationHierarchy = OrganizationModel & {
  childOrganizations?: OrganizationModel[] | null;
};

export type WorkShiftForDate = {
  date: string;
  shift: WorkShift;
};

export type WorkScheduleInfo = {
  scheduleOverrideWarning: boolean;
  warningMessages: LocaleTextEntry[];
};

export type WorkShift = {
  startTime: Time;
  endTime: Time;
  isDefault: boolean;
  order: number;
};

export type AR3IvrConfiguration = {
  maxShiftLengthThresholdInMinutes: number;
};

export type IncidentClosingScript = {
  closingScriptId: string;
  text: {
    locale: string;
    description: string;
  }[];
};

export type IncidentQuestionModel = {
  questions: AbsenceQuestionsPage[];
  preQualifyingQuestions: AbsenceQuestionsPage[];
  reasonOfAbsenceAlerts: AbsenceAlert[];
};

export type ApiValidationError = {
  errorCode: string;
  errorDescription: string;
  errorFields: string[];
  errorDescriptionLocales?: {
    description: string;
    locale: string;
  }[];
};

export type IncidentSaveResult = {
  returnValue?: {
    absenceIncidentModel: AbsenceIncident;
    absenceQuestionReturnModel: IncidentQuestionModel;
  };
  success: boolean;
  validationErrors: ApiValidationError[];
};

export type ApiCallResult = {
  success: boolean;
  validationErrors: {
    errorCode: string;
    errorDescription: string;
    errorFields: string[];
    errorDescriptionLocales?: {
      description: string;
      locale: string;
    }[];
  }[];
};

export type IncidentSubmitResult = {
  returnValue?: {
    absenceIncidentModel: AbsenceIncident;
    closingScripts: IncidentClosingScript[];
  };
  success: boolean;
  validationErrors: {
    errorCode: string;
    errorDescription: string;
    errorFields: string[];
    errorDescriptionLocales?: {
      description: string;
      locale: string;
    }[];
  }[];
};

export type IncidentsCancelResult = {
  success: boolean;
  validationErrors?: {
    errorCode: string;
    errorDescription: string;
    errorFields: string[];
    errorDescriptionLocales?: {
      description: string;
      locale: string;
    }[];
  }[];
};

export type InProgressAbsencesResult = {
  primaryReason: string;
  absenceDates: AbsenceDateModel[];
  claimStatus?: string | null;
  reportedBy?: string | null;
  absenceIncidentId: number;
};

export type LatestInProgressAbsenceResult = {
  absenceIncidentId: number;
};

export const emptyIncident = (): AbsenceIncident => {
  return {
    primaryReason: "",
    absenceDates: [],
    linkability: { canBeLinked: undefined }
  };
};

export const mapIncidentTypes = (incident: AbsenceIncident) => {
  incident.absenceDates.forEach(d => {
    d.shiftStartTime = parseISO(d.shiftStartTime.toString());
    d.shiftEndTime = parseISO(d.shiftEndTime.toString());
    d.scheduledShiftStartTime = d.scheduledShiftStartTime
      ? parseISO(d.scheduledShiftStartTime.toString())
      : undefined;
    d.scheduledShiftEndTime = d.scheduledShiftEndTime
      ? parseISO(d.scheduledShiftEndTime.toString())
      : undefined;
  });

  if (incident.returnToWorkDate) {
    incident.returnToWorkDate = parseISO(incident.returnToWorkDate.toString());
  }

  if (incident.linkability && incident.linkability.canBeLinked) {
    incident.linkability = {
      ...incident.linkability,
      latestDate: parseISO(incident.linkability.latestDate.toString())
    };
  }
};

export const localizeError = (locale?: string, error?: ApiValidationError) => {
  if (locale && error?.errorDescriptionLocales) {
    const l = error.errorDescriptionLocales.find(l =>
      matchLocales(l.locale, locale)
    );
    if (l?.description) error.errorDescription = l.description;
  }
  return error;
};
